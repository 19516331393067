$form-border-color: $base-border-color;
$form-border-color-hover: darken($base-border-color, 10);
$form-border-color-focus: $base-accent-color;
$form-border-radius: $base-border-radius;
$form-box-shadow: inset 0 1px 3px hsla(0, 0%, 0%, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px rgba(darken($form-border-color-focus, 5), 0.7);
$form-font-size: $base-font-size;
$form-font-family: $base-font-family;

fieldset {
  background: lighten($base-border-color, 10);
  border: 1px solid $base-border-color;
  margin: 0 0 ($base-line-height / 2) 0;
  padding: $base-line-height;
}

input,
label,
select {
  display: block;
  font-family: $form-font-family;
  font-size: $form-font-size;
}

label {
  font-weight: bold;
  margin-bottom: $base-line-height / 4;

  &.required:after {
    content: "*";
  }

  abbr {
    display: none;
  }
}

textarea,
#{$all-text-inputs},
select[multiple=multiple] {
  @include box-sizing(border-box);
  @include transition(border-color);
  background-color: white;
  border-radius: $form-border-radius;
  border: 1px solid $form-border-color;
  box-shadow: $form-box-shadow;
  font-family: $form-font-family;
  font-size: $form-font-size;
  margin-bottom: $base-line-height / 2;
  padding: ($base-line-height / 3) ($base-line-height / 3);
  width: 100%;

  &:hover {
    border-color: $form-border-color-hover;
  }

  &:focus {
    border-color: $form-border-color-focus;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }
}

textarea {
  resize: vertical;
}

input[type="search"] {
  @include appearance(none);
}

input[type="checkbox"], input[type="radio"] {
  display: inline;
  margin-right: $base-line-height / 4;
}

input[type="file"] {
  width: 100%;
}

select {
  width: auto;
  max-width: 100%;
  margin-bottom: $base-line-height;
}

button,
input[type="submit"],
input[type="button"] {
  @extend %button;
  @include appearance(none);
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
