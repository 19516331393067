// Icons font styles: Entypo pictograms by Daniel Bruce — www.entypo.com
// See entypo_key.png in font folder to see images, names and character codes.

@font-face {
  font-family: 'icons';
  src:url('../fonts/icons/icons.eot?mf2yaz');
  src:url('../fonts/icons/icons.eot?#iefixmf2yaz') format('embedded-opentype'),
  url('../fonts/icons/icons.woff?mf2yaz') format('woff'),
  url('../fonts/icons/icons.ttf?mf2yaz') format('truetype'),
  url('../fonts/icons/icons.svg?mf2yaz#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e600";
}
.icon-mobile:before {
  content: "\e601";
}
.icon-mouse:before {
  content: "\e602";
}
.icon-directions:before {
  content: "\e603";
}
.icon-mail:before {
  content: "\e604";
}
.icon-paperplane:before {
  content: "\e605";
}
.icon-pencil:before {
  content: "\e606";
}
.icon-feather:before {
  content: "\e607";
}
.icon-paperclip:before {
  content: "\e608";
}
.icon-drawer:before {
  content: "\e609";
}
.icon-reply:before {
  content: "\e60a";
}
.icon-reply-all:before {
  content: "\e60b";
}
.icon-forward:before {
  content: "\e60c";
}
.icon-user:before {
  content: "\e60d";
}
.icon-users:before {
  content: "\e60e";
}
.icon-user-add:before {
  content: "\e60f";
}
.icon-vcard:before {
  content: "\e610";
}
.icon-export:before {
  content: "\e611";
}
.icon-location:before {
  content: "\e612";
}
.icon-map:before {
  content: "\e613";
}
.icon-compass:before {
  content: "\e614";
}
.icon-location2:before {
  content: "\e615";
}
.icon-target:before {
  content: "\e616";
}
.icon-share:before {
  content: "\e617";
}
.icon-sharable:before {
  content: "\e618";
}
.icon-heart:before {
  content: "\e619";
}
.icon-heart2:before {
  content: "\e61a";
}
.icon-star:before {
  content: "\e61b";
}
.icon-star2:before {
  content: "\e61c";
}
.icon-thumbsup:before {
  content: "\e61d";
}
.icon-thumbsdown:before {
  content: "\e61e";
}
.icon-chat:before {
  content: "\e61f";
}
.icon-comment:before {
  content: "\e620";
}
.icon-quote:before {
  content: "\e621";
}
.icon-house:before {
  content: "\e622";
}
.icon-popup:before {
  content: "\e623";
}
.icon-search:before {
  content: "\e624";
}
.icon-flashlight:before {
  content: "\e625";
}
.icon-printer:before {
  content: "\e626";
}
.icon-bell:before {
  content: "\e627";
}
.icon-link:before {
  content: "\e628";
}
.icon-flag:before {
  content: "\e629";
}
.icon-cog:before {
  content: "\e62a";
}
.icon-tools:before {
  content: "\e62b";
}
.icon-trophy:before {
  content: "\e62c";
}
.icon-tag:before {
  content: "\e62d";
}
.icon-camera:before {
  content: "\e62e";
}
.icon-megaphone:before {
  content: "\e62f";
}
.icon-moon:before {
  content: "\e630";
}
.icon-palette:before {
  content: "\e631";
}
.icon-leaf:before {
  content: "\e632";
}
.icon-music:before {
  content: "\e633";
}
.icon-music2:before {
  content: "\e634";
}
.icon-new:before {
  content: "\e635";
}
.icon-graduation:before {
  content: "\e636";
}
.icon-book:before {
  content: "\e637";
}
.icon-newspaper:before {
  content: "\e638";
}
.icon-bag:before {
  content: "\e639";
}
.icon-airplane:before {
  content: "\e63a";
}
.icon-lifebuoy:before {
  content: "\e63b";
}
.icon-eye:before {
  content: "\e63c";
}
.icon-clock:before {
  content: "\e63d";
}
.icon-microphone:before {
  content: "\e63e";
}
.icon-calendar:before {
  content: "\e63f";
}
.icon-bolt:before {
  content: "\e640";
}
.icon-thunder:before {
  content: "\e641";
}
.icon-droplet:before {
  content: "\e642";
}
.icon-cd:before {
  content: "\e643";
}
.icon-briefcase:before {
  content: "\e644";
}
.icon-air:before {
  content: "\e645";
}
.icon-hourglass:before {
  content: "\e646";
}
.icon-gauge:before {
  content: "\e647";
}
.icon-language:before {
  content: "\e648";
}
.icon-network:before {
  content: "\e649";
}
.icon-key:before {
  content: "\e64a";
}
.icon-battery:before {
  content: "\e64b";
}
.icon-bucket:before {
  content: "\e64c";
}
.icon-magnet:before {
  content: "\e64d";
}
.icon-drive:before {
  content: "\e64e";
}
.icon-cup:before {
  content: "\e64f";
}
.icon-rocket:before {
  content: "\e650";
}
.icon-brush:before {
  content: "\e651";
}
.icon-suitcase:before {
  content: "\e652";
}
.icon-cone:before {
  content: "\e653";
}
.icon-earth:before {
  content: "\e654";
}
.icon-keyboard:before {
  content: "\e655";
}
.icon-browser:before {
  content: "\e656";
}
.icon-publish:before {
  content: "\e657";
}
.icon-progress-3:before {
  content: "\e658";
}
.icon-progress-2:before {
  content: "\e659";
}
.icon-brogress-1:before {
  content: "\e65a";
}
.icon-progress-0:before {
  content: "\e65b";
}
.icon-sun:before {
  content: "\e65c";
}
.icon-sun2:before {
  content: "\e65d";
}
.icon-adjust:before {
  content: "\e65e";
}
.icon-code:before {
  content: "\e65f";
}
.icon-screen:before {
  content: "\e660";
}
.icon-infinity:before {
  content: "\e661";
}
.icon-light-bulb:before {
  content: "\e662";
}
.icon-creditcard:before {
  content: "\e663";
}
.icon-database:before {
  content: "\e664";
}
.icon-voicemail:before {
  content: "\e665";
}
.icon-clipboard:before {
  content: "\e666";
}
.icon-cart:before {
  content: "\e667";
}
.icon-box:before {
  content: "\e668";
}
.icon-ticket:before {
  content: "\e669";
}
.icon-rss:before {
  content: "\e66a";
}
.icon-signal:before {
  content: "\e66b";
}
.icon-thermometer:before {
  content: "\e66c";
}
.icon-droplets:before {
  content: "\e66d";
}
.icon-uniE66E:before {
  content: "\e66e";
}
.icon-statistics:before {
  content: "\e66f";
}
.icon-pie:before {
  content: "\e670";
}
.icon-bars:before {
  content: "\e671";
}
.icon-graph:before {
  content: "\e672";
}
.icon-lock:before {
  content: "\e673";
}
.icon-lock-open:before {
  content: "\e674";
}
.icon-logout:before {
  content: "\e675";
}
.icon-login:before {
  content: "\e676";
}
.icon-checkmark:before {
  content: "\e677";
}
.icon-cross:before {
  content: "\e678";
}
.icon-minus:before {
  content: "\e679";
}
.icon-plus:before {
  content: "\e67a";
}
.icon-cross2:before {
  content: "\e67b";
}
.icon-minus2:before {
  content: "\e67c";
}
.icon-plus2:before {
  content: "\e67d";
}
.icon-cross3:before {
  content: "\e67e";
}
.icon-minus3:before {
  content: "\e67f";
}
.icon-plus3:before {
  content: "\e680";
}
.icon-erase:before {
  content: "\e681";
}
.icon-blocked:before {
  content: "\e682";
}
.icon-info:before {
  content: "\e683";
}
.icon-info2:before {
  content: "\e684";
}
.icon-question:before {
  content: "\e685";
}
.icon-help:before {
  content: "\e686";
}
.icon-warning:before {
  content: "\e687";
}
.icon-cycle:before {
  content: "\e688";
}
.icon-cw:before {
  content: "\e689";
}
.icon-ccw:before {
  content: "\e68a";
}
.icon-shuffle:before {
  content: "\e68b";
}
.icon-arrow:before {
  content: "\e68c";
}
.icon-arrow2:before {
  content: "\e68d";
}
.icon-retweet:before {
  content: "\e68e";
}
.icon-loop:before {
  content: "\e68f";
}
.icon-history:before {
  content: "\e690";
}
.icon-back:before {
  content: "\e691";
}
.icon-switch:before {
  content: "\e692";
}
.icon-list:before {
  content: "\e693";
}
.icon-add-to-list:before {
  content: "\e694";
}
.icon-layout:before {
  content: "\e695";
}
.icon-list2:before {
  content: "\e696";
}
.icon-text:before {
  content: "\e697";
}
.icon-text2:before {
  content: "\e698";
}
.icon-document:before {
  content: "\e699";
}
.icon-docs:before {
  content: "\e69a";
}
.icon-landscape:before {
  content: "\e69b";
}
.icon-pictures:before {
  content: "\e69c";
}
.icon-video:before {
  content: "\e69d";
}
.icon-music3:before {
  content: "\e69e";
}
.icon-folder:before {
  content: "\e69f";
}
.icon-archive:before {
  content: "\e6a0";
}
.icon-trash:before {
  content: "\e6a1";
}
.icon-upload:before {
  content: "\e6a2";
}
.icon-download:before {
  content: "\e6a3";
}
.icon-disk:before {
  content: "\e6a4";
}
.icon-install:before {
  content: "\e6a5";
}
.icon-cloud:before {
  content: "\e6a6";
}
.icon-upload2:before {
  content: "\e6a7";
}
.icon-bookmark:before {
  content: "\e6a8";
}
.icon-bookmarks:before {
  content: "\e6a9";
}
.icon-book2:before {
  content: "\e6aa";
}
.icon-play:before {
  content: "\e6ab";
}
.icon-pause:before {
  content: "\e6ac";
}
.icon-record:before {
  content: "\e6ad";
}
.icon-stop:before {
  content: "\e6ae";
}
.icon-next:before {
  content: "\e6af";
}
.icon-previous:before {
  content: "\e6b0";
}
.icon-first:before {
  content: "\e6b1";
}
.icon-last:before {
  content: "\e6b2";
}
.icon-resize-enlarge:before {
  content: "\e6b3";
}
.icon-resize-shrink:before {
  content: "\e6b4";
}
.icon-volume:before {
  content: "\e6b5";
}
.icon-sound:before {
  content: "\e6b6";
}
.icon-mute:before {
  content: "\e6b7";
}
.icon-flow-cascade:before {
  content: "\e6b8";
}
.icon-flow-branch:before {
  content: "\e6b9";
}
.icon-flow-tree:before {
  content: "\e6ba";
}
.icon-flow-line:before {
  content: "\e6bb";
}
.icon-flow-parallel:before {
  content: "\e6bc";
}
.icon-arrow-left:before {
  content: "\e6bd";
}
.icon-arrow-down:before {
  content: "\e6be";
}
.icon-arrow-up-upload:before {
  content: "\e6bf";
}
.icon-arrow-right:before {
  content: "\e6c0";
}
.icon-arrow-left2:before {
  content: "\e6c1";
}
.icon-arrow-down2:before {
  content: "\e6c2";
}
.icon-arrow-up:before {
  content: "\e6c3";
}
.icon-arrow-right2:before {
  content: "\e6c4";
}
.icon-arrow-left3:before {
  content: "\e6c5";
}
.icon-arrow-down3:before {
  content: "\e6c6";
}
.icon-arrow-up2:before {
  content: "\e6c7";
}
.icon-arrow-right3:before {
  content: "\e6c8";
}
.icon-arrow-left4:before {
  content: "\e6c9";
}
.icon-arrow-down4:before {
  content: "\e6ca";
}
.icon-arrow-up3:before {
  content: "\e6cb";
}
.icon-arrow-right4:before {
  content: "\e6cc";
}
.icon-arrow-left5:before {
  content: "\e6cd";
}
.icon-arrow-down5:before {
  content: "\e6ce";
}
.icon-arrow-up4:before {
  content: "\e6cf";
}
.icon-arrow-right5:before {
  content: "\e6d0";
}
.icon-arrow-left6:before {
  content: "\e6d1";
}
.icon-arrow-down6:before {
  content: "\e6d2";
}
.icon-arrow-up5:before {
  content: "\e6d3";
}
.icon-arrow-right6:before {
  content: "\e6d4";
}
.icon-arrow-left7:before {
  content: "\e6d5";
}
.icon-arrow-down7:before {
  content: "\e6d6";
}
.icon-arrow-up6:before {
  content: "\e6d7";
}
.icon-uniE6D8:before {
  content: "\e6d8";
}
.icon-arrow-left8:before {
  content: "\e6d9";
}
.icon-arrow-down8:before {
  content: "\e6da";
}
.icon-arrow-up7:before {
  content: "\e6db";
}
.icon-arrow-right7:before {
  content: "\e6dc";
}
.icon-menu:before {
  content: "\e6dd";
}
.icon-ellipsis:before {
  content: "\e6de";
}
.icon-dots:before {
  content: "\e6df";
}
.icon-dot:before {
  content: "\e6e0";
}
.icon-cc:before {
  content: "\e6e1";
}
.icon-cc-by:before {
  content: "\e6e2";
}
.icon-cc-nc:before {
  content: "\e6e3";
}
.icon-cc-nd:before {
  content: "\e6e4";
}
.icon-cc-pd:before {
  content: "\e6e5";
}
.icon-cc-zero:before {
  content: "\e6e6";
}
.icon-cc-share:before {
  content: "\e6e7";
}
.icon-cc-share2:before {
  content: "\e6e8";
}
.icon-danielbruce:before {
  content: "\e6e9";
}
.icon-github:before {
  content: "\e6ea";
}
.icon-github2:before {
  content: "\e6eb";
}
.icon-flickr:before {
  content: "\e6ec";
}
.icon-flickr2:before {
  content: "\e6ed";
}
.icon-vimeo:before {
  content: "\e6ee";
}
.icon-vimeo2:before {
  content: "\e6ef";
}
.icon-twitter:before {
  content: "\e6f0";
}
.icon-twitter2:before {
  content: "\e6f1";
}
.icon-facebook:before {
  content: "\e6f2";
}
.icon-facebook2:before {
  content: "\e6f3";
}
.icon-facebook3:before {
  content: "\e6f4";
}
.icon-googleplus:before {
  content: "\e6f5";
}
.icon-googleplus2:before {
  content: "\e6f6";
}
.icon-pinterest:before {
  content: "\e6f7";
}
.icon-pinterest2:before {
  content: "\e6f8";
}
.icon-tumblr:before {
  content: "\e6f9";
}
.icon-tumblr2:before {
  content: "\e6fa";
}
.icon-linkedin:before {
  content: "\e6fb";
}
.icon-linkedin2:before {
  content: "\e6fc";
}
.icon-dribbble:before {
  content: "\e6fd";
}
.icon-dribbble2:before {
  content: "\e6fe";
}
.icon-stumbleupon:before {
  content: "\e6ff";
}
.icon-stumbleupon2:before {
  content: "\e700";
}
.icon-lastfm:before {
  content: "\e701";
}
.icon-lastfm2:before {
  content: "\e702";
}
.icon-rdio:before {
  content: "\e703";
}
.icon-rdio2:before {
  content: "\e704";
}
.icon-spotify:before {
  content: "\e705";
}
.icon-spotify2:before {
  content: "\e706";
}
.icon-qq:before {
  content: "\e707";
}
.icon-instagram:before {
  content: "\e708";
}
.icon-dropbox:before {
  content: "\e709";
}
.icon-evernote:before {
  content: "\e70a";
}
.icon-skype:before {
  content: "\e70b";
}
.icon-skype2:before {
  content: "\e70c";
}
.icon-paypal:before {
  content: "\e70d";
}
.icon-picasa:before {
  content: "\e70e";
}
.icon-soundcloud:before {
  content: "\e70f";
}
.icon-mixi:before {
  content: "\e710";
}
.icon-behance:before {
  content: "\e711";
}
.icon-circles:before {
  content: "\e712";
}
.icon-vk:before {
  content: "\e713";
}
.icon-smashing:before {
  content: "\e714";
}
