// Breakpoints
///////////////////////////////////////////////////////////////////////////////

// Typography
//////////////////////////////////////////////////////////////////////////////

$sans-serif: 'Open Sans', sans-serif;
$serif: 'Bitter', serif;

$base-font-family: $sans-serif;
$header-font-family: $sans-serif;

// Sizes
///////////////////////////////////////////////////////////////////////////////
$base-font-size: 1em;
$base-line-height: $base-font-size * 1.4;
$base-border-radius: em(3);

// Colors
///////////////////////////////////////////////////////////////////////////////

$red: #AC4639;
$gray: #403E3C;
$navy: #39537A;
$light-beige: #FAF0DF;
$medium-beige: #E3D0A4;
$dark-beige: #B39A65;
$blue: #477DCA;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #DDD;
$light-red: #FBE3E4;
$light-yellow: #FFF6BF;
$light-green: #E6EFC2;

// Body Color
$base-body-color: white;

//  Font Colors
$base-font-color: $gray;
$base-accent-color: $navy;

//  Text Link Colors
$base-link-color: $base-accent-color;
$hover-link-color: darken($base-accent-color, 15);

// Border color
$base-border-color: $light-gray;

// Flash Colors
$error-color: $light-red;
$notice-color: $light-yellow;
$success-color: $light-green;


