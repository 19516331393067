

// Hide only visually, but have it available for screenreaders: h5bp.com/v
@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin respond-to($media) {
  @media #{$media} {
    @content
  }
}

@mixin respond-to-fallback($media, $wrapper-class) {
  .#{$wrapper-class} & {
    @content;
  }
  @include respond-to($media) {
    @content;
  }
}

@mixin flash($color) {
  background: $color;
  color: darken($color, 60);
  font-weight: bold;
  margin-bottom: $base-line-height / 2;
  padding: $base-line-height / 2;

  a {
    color: darken($color, 70);

    &:hover {
      color: darken($color, 90);
    }
  }
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}