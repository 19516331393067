@charset "UTF-8";

/*
 * @file
 * This file is the main scss file for the mps theme. It is
 * used for ordering your partials. Dont add additional styles in
 * this file, but rather put them in the appropriate partial or create
 * a new one and add it here.
 *
 */

// In order to load fonts this way, they have to be first in the file
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,400,300,700);

@import "bourbon/bourbon";
@import "base/grid-settings"; // Must come after Bourbon and before Neat
@import "neat/neat";
@import "base/base";
@import "components/menus";
@import "components/modal";
@import "layout/layout";







