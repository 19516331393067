ul,
ol {
  margin: 0;
  padding: 0;
  list-style-position: outside;

  li {
    padding-bottom: 0.5rem;
  }

  ul,
  ol {
    &:first-of-type {
      padding-top: 0.5rem;
    }
  }
}

ul {
  list-style-type: disc;
  margin-bottom: $base-line-height / 2;
  padding-left: $base-line-height;
}

ol {
  list-style-type: decimal;
  margin-bottom: $base-line-height / 2;
  padding-left: $base-line-height;
}


dl {
  line-height: $base-line-height;
  margin-bottom: $base-line-height / 2;

  dt {
    font-weight: bold;
    margin-top: $base-line-height / 2;
  }

  dd {
    margin: 0;
  }
}
