/*
 * This file holds all of the menu styles
 *
 */

#site-navigation {
  z-index: 200;
  position: relative;
  width: 100%;
  color: #fff;

  .close-btn {
    display: none;
  }

  .menu-top-nav-container {
    z-index: 2;
    position: relative;
  }

  li {
    display: block;

    a {
      display: block;
      color: #fff;
      font-size: em(15);
      line-height: 1;
      text-transform: uppercase;
      font-weight: bold;
      outline: none;

      &:focus,
      &:hover {
        color: #fff;
        background: rgba(255, 255, 255, 0.1);
      }
    }

    &.is-active a {
      color: #fff;
    }
  }
}

.mobile-header {
  width: 100%;
  background: $navy;
  display: block;
  padding: em(12);
}

.mobile-hme-btn {
  color: white;
  text-align: center;
}

.close-btn {
  position: absolute;
  top: em(6);
  left: em(10);
}

a.mobile-link {
  color: #fff;
  float: right;
  display: inline-block;
  margin-right: 20px;
  line-height: 1.5;

  span {
    position: relative;
    font-size: 24px;
    top: 4px;
  }

  :hover {
    color: white;
  }
}

.close-btn,
.nav-btn {
  color: #fff;

  &:hover {
    color: darken(#fff, 15%);
  }

  span {
    color: #fff;
    @include fontSize(26px);
    position: relative;
    top: 6px;
    display: inline-block;
  }
}

@include respond-to($large-up) {

  .navbar {
    @include linear-gradient($navy, darken($navy, 5%));
  }

  .main-menu {
    @include outer-container();
  }

  .mobile-header{
    @include visuallyhidden;
  }

  #site-navigation .close-btn {
    @include visuallyhidden;
  }

  #site-navigation ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
    text-align: center;

    li {
      position: relative;
      display: inline-block;
      padding: 0;
    }

    a {
      display: block;
      text-decoration: none;
      padding: em(14) em(14);
    }

    ul {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
      background: $navy;
      display: none;
      float: left;
      left: 0;
      position: absolute;
      top: em(35);
      z-index: 99999;

      a {
        width: 200px;
      }
    }

    ul ul {
      left: 100%;
      top: 0;
    }
  }

  .main-navigation ul ul li {
  }
  .main-navigation li:hover > a {
  }
  .main-navigation ul ul :hover > a {
  }
  .main-navigation ul ul a:hover {
  }
  .main-navigation ul li:hover > ul {
    display: block;
  }
  .main-navigation .current_page_item a,
  .main-navigation .current-menu-item a {
  }
}

@include respond-to($large)  {

  #site-navigation {
    position: absolute;
    top: 0;
    padding-top: em(50);

    &:not(:target) {
      z-index: 1;
      height: 0;
    }

    &:target .close-btn {
      display: block;
    }

    .menu-top-nav-container {
      position: relative;
      padding: 0;
    }

    li {
      position: relative;
      border-top: 1px solid rgba(255, 255, 255, 0.1);

      &:last-child {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }

      &.current-menu-item:after {
        z-index: 50;
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        right: -0.03125em;
        margin-top: -0.625em;
        border-top: 0.625em transparent solid;
        border-bottom: 0.625em transparent solid;
        border-right: 0.625em white solid;
      }

      a {
        padding: em(16) em(20);
        font-weight: bold;
      }

      ul a {
        padding: em(16) em(20) em(16) em(32);
        font-weight: normal;
      }
    }
  }

  .js-ready {
    #site-navigation {
      height: 100%;
      width: 70%;
      left: -70%;
      background: $navy;
      box-shadow: inset -1.5em 0 1.5em -0.75em rgba(0, 0, 0, 0.25);

      .menu-top-nav-container {
        background: transparent;
      }

      .close-btn {
        display: block;
        opacity: 0.7;

        &:focus,
        &:hover {
          opacity: 1;
        }
      }
    }

    .inner-page {
      left: 0;
    }
  }

  .js-nav {

    #site-navigation {
      overflow: hidden;
    }

    .inner-page {
      left: 70%;
    }
  }

  .csstransforms3d.csstransitions.js-ready {

    #site-navigation {
      left: 0;
      @include transform(translate3d(-100%, 0, 0));
      @include backface-visibility(hidden);

      .menu-top-nav-container {
        opacity: 0.7;
        -webkit-transition: opacity 300ms 100ms, -webkit-transform 500ms ease;
        -webkit-transition-delay: ease, 0s;
        -moz-transition: opacity 300ms 100ms ease, -moz-transform 500ms ease;
        -o-transition: opacity 300ms 100ms ease, -o-transform 500ms ease;
        transition: opacity 300ms 100ms ease, transform 500ms ease;
        @include transform(translate3d(0, 0, 0) scale3d(0.9, 0.9, 0.9));
        @include transform-origin( 50% 0);
      }
    }

    .inner-page {
      left: 0 !important;
      @include transform(translate3d(0, 0, 0));
      -webkit-transition: -webkit-transform 500ms ease;
      -moz-transition: -moz-transform 500ms ease;
      -o-transition: -o-transform 500ms ease;
      transition: transform 500ms ease;
      @include backface-visibility(hidden);
    }

    &.js-nav {

      .inner-page {
        @include transform(translate3d(70%, 0, 0) scale3d(1, 1, 1));
      }

      #site-navigation .menu-top-nav-container {
        opacity: 1;
        @include transform(translate3d(0, 0, 0));
      }
    }
  }
}










