body {

}

#page {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.inner-page {
  position: relative;
  width: 100%;
}

#masthead {
  z-index: 100;
  position: relative;
  background: url("../img/aspen-header.jpg") no-repeat center top / cover;

  .site-branding {
    display: block;
  }

  .site-logo {
    @include span-columns(4);
    background: rgba(white, 0.65);
    text-align: center;

    @include respond-to($large) {
      //@include shift(1);
      background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.45) 15%, rgba(255, 255, 255, 0.45) 85%, transparent);
      @include span-columns(6);
    }

    @include respond-to($medium) {
      //@include shift(1);
      @include span-columns(6);
    }

    @include respond-to($small) {
      @include span-columns(12);
    }
  }

  .site-title {
    margin: 0 auto;
    padding: 0.5em;

    a {
      background: url(../img/mps-logo.svg) no-repeat;
      background-size: 300px 149px;
      height: 149px;
      width: 300px;
      display: block;
      margin: auto;

      @include respond-to($large) {
        background-size: 230px 115px;
        height: 115px;
        width: 230px;
      }

      @include respond-to($medium) {
        background-size: 200px 100px;
        height: 100px;
        width: 200px;
      }

      span {
        @include visuallyhidden;
      }
    }

    @include respond-to($small) {
     @include span-columns(12);
      margin: 0 auto;
      padding: 12px 0;

      a {
        margin: 0 auto;
      }
    }
  }

  .site-description {
    font-size: em(18);
    font-weight: bold;
    font-style: italic;
    padding: 0 1rem 1rem;
    color: $red;

    @include respond-to($small) {
      text-align: center;
    }
  }
}

.block,
.menu-top-nav-container {
  @include outer-container;
}

.shadow {
  @include outer-container;
  @include row();
  box-shadow: 0 4px 10px rgba(black, 0.4);
}

#site-navigation {
  @include row;
}

#content {
  @include row;
  background: #fff;
}

#main {
  padding: 1.5em;
}

#primary {
  @include span-columns(12);
}

article.page {
  @include row();
}

.entry-image {
  @include span-columns(6);

  .home & {
    @include span-columns(12);
    margin: 1.5em 0 0;
  }

  @include respond-to($medium) {
    @include span-columns(12);
  }
}

.entry-content {
  @include span-columns(6);

  .home & {
    @include span-columns(12);
    @include row();
  }

  @include respond-to($medium) {
    @include span-columns(12);
  }
}

.page-id-14 .entry-content {
  @include span-columns(12);
}

.contact-form {
  @include span-columns(6);

  @include respond-to($medium) {
    @include span-columns(12);
  }
}

.hp-content,
.hp-contact {
  @include span-columns(6);

  @include respond-to($medium) {
    @include span-columns(12);
  }
}

.hp-contact {
  @include omega();
  padding: 1.5rem;
  background: lighten($navy, 60);
  border: 1px solid #bbb;
}

.site-info {
  background: lighten($light-beige, 5%);
  padding: 2em .5em;
  margin-bottom: 0em;
  @include row();

  .affiliate {
    @include span-columns(6);

    h4 {
      margin-left: 15px;
    }

    .home & {
      @include span-columns(3);
    }

    p {
      text-align: center;
    }

    @include respond-to($medium) {
      @include span-columns(12);
    }
  }

  .footer-contact {
    @include span-columns(3);
    @include shift(3);

    .home & {
      @include span-columns(3);
      @include shift(6);
    }

    @include respond-to($medium) {
      @include span-columns(12);
      //@include shift(0);
      text-align: center;
    }
  }
}

#colophon {
  border-top: 1px solid $light-beige;
}

.site-info,
.copyright {
  p {
    margin-bottom: 0;
    color: #666;
    font-size: em(14);
  }
}

.copyright {
  text-align: center;
  margin: 2em 0;
}

.ninja-forms-required-items {
  font-size: em(13);
}

.btm-location-map {
  margin-bottom: 1em;

  .home & {
    margin-bottom: 0.3em;
  }
}

.entry-title {
  font-weight: bold;
  color: $navy;
}

.entry-content {
  margin-top: 0;

  h2 {
    font-size: em(24);
    font-weight: bold;
    font-style: italic;
    color: $navy;
  }

  h3 {
    font-size: em(22);
    font-weight: bold;
  }
}

.home .btm-location-map {
  height: 275px !important;
}

.home .modal .btm-location-map {
  height: 400px !important;
}

.service-nav {
  text-align: right;
  padding: 0.5em 0 0;

  .button {
    background-image: linear-gradient($navy, darken($navy, 7));
    color: white;
    padding: 0.5rem 1rem;
    transition: all 200ms ease;

    &:hover {
      background-image: linear-gradient(darken($navy, 3), darken($navy, 10));
    }

    &.button-left {
      border-right: 1px solid lighten($navy, 2);
      border-radius: 0 0 0 4px;
    }

    &.button-right {
      border-left: 1px solid darken($navy, 12);
      border-radius: 0 0 4px 0;
      margin-left: -4px;
    }
  }

  @include respond-to($large) {
    display: none;
  }
}

.hp-extra {
  @include span-columns(12);
  padding-top: 2rem;
}

.hp-left-column,
.hp-right-column {
  @include span-columns(6);
  @include pad(1rem);

  @include respond-to($medium) {
    @include span-columns(12);
  }
}

.hp-right-column {
  @include omega();
}