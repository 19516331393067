/*
 * This file holds all of the default wordpress styles
 *
 */

/*--------------------------------------------------------------
 Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
}

.screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar */
}

/*--------------------------------------------------------------
 Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}
.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}
.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto;
}

/*--------------------------------------------------------------
 Clearings
--------------------------------------------------------------*/
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
  content: '';
  display: table;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
  clear: both;
}

/*--------------------------------------------------------------
 Widgets
--------------------------------------------------------------*/
.widget {
  margin: 0 0 1.5em;
}

/* Make sure select elements fit in widgets */
.widget select {
  max-width: 100%;
}

/* Search widget */
.widget_search .search-submit {
  display: none;
}

/*--------------------------------------------------------------
 Posts and pages
--------------------------------------------------------------*/
.sticky {
}
.hentry {
  margin: 0 0 1.5em;

  &.page {
    margin: 0;
  }
}
.byline,
.updated {
  display: none;
}
.single .byline,
.group-blog .byline {
  display: inline;
}
.page-content,
.entry-content,
.entry-summary {
  margin: 1.5em 0 0;
}
.page-links {
  clear: both;
  margin: 0 0 1.5em;
}

/*--------------------------------------------------------------
 Asides
--------------------------------------------------------------*/
.blog .format-aside .entry-title,
.archive .format-aside .entry-title {
  display: none;
}

/*--------------------------------------------------------------
 Comments
--------------------------------------------------------------*/
.comment-content a {
  word-wrap: break-word;
}
.bypostauthor {
}


/*--------------------------------------------------------------
 Media
--------------------------------------------------------------*/
.page-content img.wp-smiley,
.entry-content img.wp-smiley,
.comment-content img.wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}
/* Make sure embeds and iframes fit their containers */
embed,
iframe,
object {
  max-width: 100%;
}

/*--------------------------------------------------------------
 Captions
--------------------------------------------------------------*/
.wp-caption {
  border: 1px solid #ccc;
  margin-bottom: 1.5em;
  max-width: 100%;
}
.wp-caption img[class*="wp-image-"] {
  display: block;
  margin: 1.2% auto 0;
  max-width: 98%;
}
.wp-caption-text {
  text-align: center;
}
.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

/*--------------------------------------------------------------
 Galleries
--------------------------------------------------------------*/
.gallery {
  margin-bottom: 1.5em;
}
.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}
.gallery-columns-2 .gallery-item {
  max-width: 50%;
}
.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}
.gallery-columns-4 .gallery-item {
  max-width: 25%;
}
.gallery-columns-5 .gallery-item {
  max-width: 20%;
}
.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}
.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}
.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}
.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}
.gallery-caption {}
